@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  scroll-behavior: smooth;
}

.hamburger {
  width: 40px;
  margin-right: 10px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger-menu {
  background-color: black;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  transform: translateX(-100%);
  transition: all 0.35s ease-in-out;
}

.open-menu {
  transform: translateX(0%);
}

.hamburger-menu a {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 45px;
  text-align: center;
  margin: 30px auto;
}

.hamburger-menu a:hover {
  text-decoration: underline white;
}

.cancel {
  width: 30px;
}

.cancel:hover {
  cursor: pointer;
}
